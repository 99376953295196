:root {
  /*
   * Typography
   * ======================================================================== */
  --border-radius: 4px;

  /*
   * Layout
   * ======================================================================== */
  --actions-height: 84px;
  --table-header-height: 2.375rem;
  --table-footer-height: 2.375rem;
  --table-row-height: 2.375rem;
  --sider-width: 237px;
  --collapsed-sider-width: 80px;
  --bottom-menu-height: 48px;

  /*
   * z-indexes
   * ======================================================================== */
  /* --zindex-modal: 1000; */
  --zindex-bottom-menu: 101;
  --zindex-fixed-actions: 100;
  --zindex-grid-header: 100;
  --zindex-card-hover: 99;

  /*
   * Media queries breakpoints
   * ======================================================================== */
  @custom-media --mobile (width < 769px);
  @custom-media --tablet-down (width < 1025px);
  @custom-media --tablet-only (width >= 769px) and (width < 1025px);
  @custom-media --tablet-up (width >= 769px);
  @custom-media --laptop (width >= 1025px);
  @custom-media --ie11 screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none);

  /*
   * Colors
   * ======================================================================== */
  --primary-color: #1c5593;
  --primary-2: #bae7ff;
  --primary-3: #c1e3ff;
  --primary-6: #4094da;
  --primary-9: #113e6f;
  --primary-10: #eef7ff;
  --link-hover: #0072c3;
  --layout-header-color: var(--primary-2);
  --layout-header-color-hover: #fff;
  --text-color: rgba(0, 0, 0, 0.65);
  --icon-color: #878d96;
  --border-color: #dde1e6;
  --error-color: #da1e28;

  --muted-white: #ebebeb;
  --white: #fff;
  --black: #000;
  --background: #f3f5f7;
  /*
   * Margins
   * ======================================================================== */
  --gap: 1rem;

  --margin-xs: calc(var(--gap) * 0.25);
  --margin-sm: calc(var(--gap) * 0.5);
  --margin-md: calc(var(--gap));
  --margin-lg: calc(var(--gap) * 1.5);
  --margin-xl: calc(var(--gap) * 2);

  /*
   * Necessary for the Tailwind transform classes
   * ======================================================================== */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
/*
 * Custom selectors
 * ======================================================================== */
@custom-selector :--hover :hover, :focus;
