.alert{
  @apply relative py-2 pr-4 pl-9 rounded border border-solid duration-300 ease-in-out;
}

.banner{
  @apply rounded-none border-0;
}

.closable{
  padding-right: 30px;
}

.icon{
  @apply absolute top-2 left-4 w-4;
}

.hasDescription{
  @apply py-4 pr-4 pl-16;
  &.closable{
    padding-right: 30px;
  }
  .icon{
    @apply top-4 left-6 w-6 h-6;
  }
  .close{
    @apply top-2.5;
  }
}

.close{
  @apply absolute top-1.5 right-2.5 w-3 h-3;
}

.info{
  @apply bg-primary-tint2 border-primary-lighter;
  .icon{
    @apply text-primary-lighter;
  }
}
.success{
  @apply bg-feedback-success-tint border-feedback-success;
  .icon{
    @apply text-feedback-success;
  }
}
.warning{
  @apply bg-feedback-warning-tint border-feedback-warning;
  .icon{
    @apply text-feedback-warning;
  }
}
.error{
  @apply bg-feedback-error-lighter border-feedback-error;
  .icon{
    @apply text-feedback-error;
  }
}
.gray{
  @apply bg-gray-lighter border-none text-gray-light;
}

.closed{
  @apply border-0 m-0 py-0 max-h-0 overflow-hidden;
}