.trigger {
    @apply outline-none;
    &.triggerArrow {
        @apply relative;
        svg {
            margin-right: 14px !important;
        }
        &::before {
            content: "";
            @apply absolute right-1.5 top-1/2 w-1 h-1 border-solid border-0 border-b border-r border-primary-lighter transform rotate-45 -translate-y-0.5;
        }
    }
}

.item {
    @apply relative flex p-3 w-full bg-transparent hover:bg-gray-lighter hover:outline-none rounded-none pointer-events-auto cursor-pointer;
    &.selected {
        @apply bg-primary-tint;
    }
    &.disabled {
        @apply pointer-events-none cursor-not-allowed;
    }
    &.first {
        @apply rounded-t;
    }
    &.last {
        @apply rounded-b;
    }
    > * {
        @apply flex items-center static;
    }
    .inner {
        @apply w-full;
    }

    span {
        @apply text-ellipsis overflow-x-hidden whitespace-nowrap block;
    }

    &.hasIcon {
        @apply py-2.5;
    }

    &.checkboxItem {
        @apply m-0 py-2.5;
    }
}

.check {
    @apply ml-auto text-primary-link;
}

.icon {
    @apply mr-1 min-w-1.5;
}

.title {
    @apply p-3 pb-1 w-full rounded-none flex bg-white;
}

.smallWidthMargin {
    @apply mr-4;
}

.content {
    @apply bg-white rounded-lg shadow-grid w-72 m-0 overflow-hidden;

    &.fitWidth {
        @apply min-w-72;
        width: var(--radix-dropdown-menu-trigger-width);
    }

    &.smallWidth {
        @apply w-48;
    }

    &[data-state="closed"] .item {
        @apply pointer-events-none;
    }

    // noCloseAnimation prevents weird behavior when the trigger disappears (charts)
    &.noCloseAnimation[data-state="closed"] {
        transform: translate(-100vw, -100vh) !important;
    }

    &.iconsOnly {
        @apply w-12;
    }

    /*
    FIXME: animations that change the height cause a bug with the resizeObserver
    &:not(.disableAnimations) {
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        &[data-side="top"] {
            @apply origin-bottom;
            animation-name: topEnter;
            &[data-state="closed"] {
                animation-name: topExit;
            }
        }
        &[data-side="right"] {
            @apply origin-left;
            animation-name: rightEnter;
            &[data-state="closed"] {
                animation-name: rightExit;
            }
        }
        &[data-side="bottom"] {
            @apply origin-top;
            animation-name: bottomEnter;
            &[data-state="closed"] {
                animation-name: bottomExit;
            }
        }
        &[data-side="left"] {
            @apply origin-right;
            animation-name: leftEnter;
            &[data-state="closed"] {
                animation-name: leftExit;
            }
        }
    }*/
}

.body {
    @apply max-h-80 relative;
}

.virtuoso {
    @apply max-h-full;
}

//Angles larger than 50deg cause zero-sized error with virtuoso
@keyframes bottomEnter {
    from {
        @apply opacity-0 max-h-0;
        transform: rotateX(-50deg);
    }
    to {
        @apply opacity-100 max-h-screen;
        transform: rotateX(0deg);
    }
}
@keyframes bottomExit {
    from {
        @apply opacity-100 max-h-screen;
        transform: rotateX(0deg);
    }
    to {
        @apply opacity-0 max-h-0;
        transform: rotateX(-90deg);
    }
}
@keyframes topEnter {
    from {
        @apply opacity-0 max-h-0;
        transform: rotateX(50deg);
    }
    to {
        @apply opacity-100 max-h-screen;
        transform: rotateX(0deg);
    }
}
@keyframes topExit {
    from {
        @apply opacity-100 max-h-screen;
        transform: rotateX(0deg);
    }
    to {
        @apply opacity-0 max-h-0;
        transform: rotateX(90deg);
    }
}
@keyframes leftEnter {
    from {
        @apply opacity-0 max-w-0;
        transform: rotateY(50deg);
    }
    to {
        @apply opacity-100 max-w-fit;
        transform: rotateY(0deg);
    }
}
@keyframes leftExit {
    from {
        @apply opacity-100 max-w-fit;
        transform: rotateY(0deg);
    }
    to {
        @apply opacity-0 max-w-0;
        transform: rotateY(90deg);
    }
}
@keyframes rightEnter {
    from {
        @apply opacity-0 max-w-0;
        transform: rotateY(-50deg);
    }
    to {
        @apply opacity-100 max-w-fit;
        transform: rotateY(0deg);
    }
}
@keyframes rightExit {
    from {
        @apply opacity-100 max-w-fit;
        transform: rotateY(0deg);
    }
    to {
        @apply opacity-0 max-w-0;
        transform: rotateY(-90deg);
    }
}
