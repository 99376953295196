@import '../../styles/variables.css';

.wrapper {
  position: relative;

  input {
    padding-left: 44px;
    
    &::placeholder {
      color: #878d96;
    }
  }

  &.filter {
    @apply ml-0 font-bold border-none cursor-pointer;
    
    & input {
      @apply border-none cursor-pointer;
      height: 36px;
      border-radius: 36px;

      &::placeholder {
        @apply font-bold;
      }
    }
  
    &:hover {
      @apply text-gray-light;
      
      & input {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
      }
    }
  }
}

.icon {
  @apply absolute left-2 top-1/2  text-gray-light;
  transform: translateY(-50%);
}
