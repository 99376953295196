@keyframes contentShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title {
  @apply m-0 font-black text-primary-darker text-lg p-6;
  letter-spacing: 0.1px;

  .forDropdown & {
    @apply px-3 py-4;
  }
}

.footer {
  @apply px-6 py-4 grid grid-cols-2 gap-4;

  & > button {
    @apply w-full;
  }
}

.oneCol {
  @apply grid-cols-1;
}

.overlay {
  @apply fixed inset-0 z-200;
  background-color: rgba(0, 0, 0, 0.5);
  animation: contentShow 350ms cubic-bezier(0.16, 1, 0.3, 1);
}

.contentLayer {
  @apply fixed inset-0 flex items-center justify-center z-200;
}

.s { width: 27.5rem; }
.m { width: 41rem; }
.l { width: 47rem; }
.xl { width: 53.5rem; }
.xxl { width: 76.5rem; }

.content {
  @apply bg-white rounded-lg fixed z-200 max-w-none max-h-85vh flex flex-col;
  @screen desktop {
    @apply left-1/2 top-1/2 max-w-full;
    transform: translate(-50%, -50%);
  }
  @screen tablet {
    @apply top-1/2 max-w-none;
    left: 5%;
    width: 90% !important;
    transform: translateY(-50%);
  }
  @screen mobile {
    @apply top-0 left-0 rounded-none h-full max-h-full mobile:z-1000 max-w-full w-full;
  }
  animation: contentShow 350ms cubic-bezier(0.16, 1, 0.3, 1);

  & :focus {
    @apply outline-none;
  }
}

.innerContent {
  @apply relative p-6 border-t border-b border-l-0 border-r-0 border-solid border-disabled mobile:flex-grow overflow-y-auto text-base;
}

.noHeader {
  @apply border-t-0;
}

.noFooter {
  @apply border-b-0;
}

.noPadding {
  @apply p-0;
}

.description {
  @apply m-0 font-bold text-primary-darker text-base mb-2;
  letter-spacing: 0.1px;
}

.noUndo {
  @apply m-0 text-gray-light text-base mb-2;
  letter-spacing: 0.1px;
}

.closeButton {
  @apply top-5 right-5;
  position: absolute !important;

  .forDropdown & {
    @apply top-3 right-2;
  }
}
