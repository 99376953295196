@import '../../styles/variables.css';

.stepContainer {
  border-top: 4px solid var(--primary-6);
}

.step {
  @apply flex py-2 text-gray-light;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  
  &.current {
    color: var(--primary-6);
  }
}
