:root {
  --toastify-color-light: #fff;
  --toastify-color-success: #daf8d6;
  --toastify-color-warning: #ffefb7;
  --toastify-color-error: #fed7d4;
  --toastify-color-info: #c1e3ff;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: #3AA166;
  --toastify-icon-color-warning: #e9af00;
  --toastify-icon-color-error: #da1e28;
  --toastify-icon-color-info: #1c5593;
  --toastify-text-color-info: #000;
  --toastify-text-color-success: var(--toastify-icon-color-success);
  --toastify-text-color-warning: var(--toastify-icon-color-warning);
  --toastify-text-color-error: var(--toastify-icon-color-error);
  --toastify-text-color-info: var(--toastify-icon-color-info);
  --toastify-toast-width: 650px;
  --toastify-color-progress-success: var(--toastify-icon-color-success);
  --toastify-color-progress-warning: var(--toastify-icon-color-warning);
  --toastify-color-progress-error: var(--toastify-icon-color-error);
  --toastify-color-progress-info: var(--toastify-icon-color-info);
}

.Toastify__close-button {
  color: inherit;
}
.Toastify__toast-container {
  @apply max-w-full;
}
.Toastify__toast {
  font-family: 'Lato', sans-serif;
  @apply text-base font-bold;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  @apply bg-feedback-success;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  @apply bg-feedback-warning;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  @apply bg-feedback-error; 
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
  @apply bg-primary; 
}