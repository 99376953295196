@import '../../styles/variables.css';

.wrapper {
  @apply flex items-center w-full;
}

.selectWrapper {
  @apply flex-grow ml-4;
}

.icon {
  @apply flex-shrink-0;
}
