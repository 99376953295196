.wrapper {
  @apply flex items-center justify-center w-full;
  &:hover {
    .separator,
    .control {
      @apply border-primary-lighter;
    }
  }

  .pickerLeft{
    @apply pr-3.5;
  }
  .pickerRight{
    @apply pl-1;
  } 
}

.separator {
  @apply z-10 bg-white -ml-4 -mr-1 mt-0.5 h-11 border-solid border-y border-x-0 border-gray-border duration-100;
  line-height: 2.8;
}
