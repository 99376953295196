@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:global(div[data-radix-popper-content-wrapper]) {
  z-index: 999999 !important;
}

.root {
  @apply bg-primary-darker p-2 rounded-lg flex items-center justify-center flex-col text-sm leading-4 font-bold text-white select-none max-w-md text-justify;
  letter-spacing: 0.1px;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  opacity: 0;
}

.root[data-state='instant-open'] {
  @apply opacity-100;
}

.root[data-state='open'] {
  @apply opacity-100;
  animation-name: fadeIn;
}

.root[data-state='delayed-open'][data-side='top'] {
  @apply opacity-100;
  animation-name: fadeIn;
}

.root[data-state='delayed-open'][data-side='right'] {
  @apply opacity-100;
  animation-name: fadeIn;
}

.root[data-state='delayed-open'][data-side='bottom'] {
  @apply opacity-100;
  animation-name: fadeIn;
}

.root[data-state='delayed-open'][data-side='left'] {
  @apply opacity-100;
  animation-name: fadeIn;
}

.trigger {
  @apply p-0;
  font-weight: inherit;
}

.arrow {
  @apply fill-primary-darker;
}