.select {
  @apply text-base;
}

.dropdownIndicator {
  @apply text-gray-light transition-all duration-300;
  &.dropdownIndicatorOpen {
    @apply transform rotate-180;
  }
}

.multiValueRemove {
  @apply duration-200 rounded-tr rounded-br rounded-tl-none rounded-bl-none flex items-center;
}

div.container {
  &.standalone {
    @apply rounded-3xl duration-300;
    &:hover,
    &:focus {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    }
  }
}

div.control {
  @apply border-gray-border;
  :global(.changedValue) & {
    @apply border-feedback-success;
  }
  
  &.standalone {
    @apply rounded-3xl border-transparent;
    &:hover {
      @apply border-transparent;
    }
  }

  &.hasError {
    @apply border-solid border border-feedback-error;
  }
  &.disabled {
    @apply bg-gray-lighter text-muted;
    * {
      @apply text-muted;
    }
  }
}

div.valueContainer {
  &.standalone {
    @apply pt-0;
  }
}

div.singleValue {
  &.standalone {
    @apply font-bold text-primary-lighter;
  }
}

div.menu {
  @apply z-3000;
  &.standalone {
    @apply border-transparent;
  }
}
