.root {
  @apply inline-flex rounded;
}

.item {
  @apply bg-gray-lighter text-gray-light flex items-center justify-center cursor-pointer;
}

.item:first-child {
  @apply rounded-tl rounded-bl;
}

.item:last-child {
  @apply rounded-tr rounded-br;
}

.item:hover {
  @apply bg-primary-lightest text-primary-link;
}

.item[data-state='on'] {
  @apply bg-primary-tint text-primary-lighter;
}

.disabled {
  .item {
    @apply text-muted bg-gray-lighter cursor-not-allowed;
  }  
}

.s {
  .item {
    @apply py-0.5 px-1 space-x-1;
  }
}

.m {
  .item {
    @apply py-1.5 px-2 space-x-2;
  }
}
