.wrapper {
  @apply text-gray-light flex items-center shrink-0 pl-3 pr-2 select-none bg-white transition-all duration-300 rounded border-solid border border-gray-border;
  &[disabled] {
    @apply bg-disabled cursor-not-allowed pr-3;
  }

  /* default size */
  min-height: 44px;
  line-height: 42px;

  &:hover {
    @apply text-gray-light;
  }

  &.hasValue {
    @apply text-gray;
    &:hover {
      @apply text-gray;
    }
  }

  &.small {
    min-height: 36px;
    line-height: 36px;
  }

  &.round {
    @apply border-none font-bold text-gray-light;

    border-radius: 36px;
    letter-spacing: 0.1px;

    &:hover {
      @apply text-gray-light;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    }

    &.hasValue {
      @apply text-primary-lighter;

      svg {
        @apply fill-current;
      }
    }

    &.opened {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    }
  }

  &.templates {
    @apply font-bold border-0 bg-gray-lighter text-gray-light;
    min-height: 36px;
    line-height: 36px;
    border-radius: 36px;

    // Disable border flicker when exiting hover
    outline: 0 !important;

    &:hover {
      @apply text-gray-light;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    }

    &.small {
      min-height: 28px;
      line-height: 28px;
    }
  }

  &.graybg {
    @apply bg-gray-lighter;
  }

  &.tags.hasValue {
    @apply text-gray-light;
    &:hover {
      @apply text-gray-light;
    }
  }

  &.hasError {
    @apply border-solid border border-feedback-error;
  }
}

.item {
  @apply mr-2 text-gray-light overflow-ellipsis;
}

.prefixWrapper {
  @apply flex mr-2 shrink-0;
  &.hasValue {
    @apply text-primary-lighter;
  }
}

.arrow {
  @apply ml-2 shrink-0 transition-all duration-300;

  &.rotated {
    transform: rotate(180deg);
  }
}

.tag {
  @apply py-1 px-2 bg-gray-lighter rounded text-sm leading-4;
  & + & {
    @apply ml-1;
  }
}

.selectionWrapper {
  @apply w-full overflow-hidden overflow-ellipsis whitespace-nowrap;
  &.asMultiTags {
    @apply flex flex-wrap py-0.5;
  }
}
