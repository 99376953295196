.root {
  @apply relative flex items-center my-1 w-fit;

  .indicator {
    @apply w-full h-full bg-primary-darker flex;

    svg {
      @apply fill-white;
    }
  }

  .boxContainer {
    @apply flex;
  }

  .checkbox {
    @apply w-full h-full bg-white border border-solid border-muted rounded-sm p-0;

    &:hover {
      .indicator {
        @apply bg-primary;
      }
    }

    &::after {
      @apply absolute top-0 left-0 w-full h-full;
      content: "";
    }
  }

  .checkbox[data-state="unchecked"] {
    &:hover {
      @apply border-primary-darker;
    }
  }

  .checkbox[data-state="checked"] {
    @apply border-primary-darker;
    &:hover {
      @apply border-primary;
    }
  }

  &.s {
    .boxContainer {
      @apply w-4 min-w-1 h-4 p-0.5;
    }
    .label {
      @apply ml-1;
    }
    .icon{
      @apply w-4 ml-1 min-w-1;
    }
  }

  &.m {
    .boxContainer {
      @apply w-6 min-w-1.5 h-6 p-1;
    }
    .label {
      @apply ml-2;
    }
    .icon{
      @apply ml-1 w-6 min-w-1.5;
    }
  }
}

.root.disabled {
  .checkbox {
    @apply border border-solid rounded-sm p-0 border-muted bg-disabled cursor-not-allowed;

    &:hover {
      @apply border-muted;

      .indicator {
        @apply bg-disabled;
      }
    }
  }
}

.root.indeterminate {
  .checkbox {
    @apply border-primary-darker;
    .indicator {
      @apply relative bg-white;

      div {
        @apply bg-primary-darker absolute top-0.5 right-0.5 bottom-0.5 left-0.5 rounded-sm;
      }
    }
  }
}
