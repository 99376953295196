@import '../../styles/variables.css';

.wrapper {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  height: 15px;
  overflow: hidden;
}

.bar {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;

  &.small {
    background-color: #daf8d6;
    color: #3aa266;
  }
  &.middle {
    background-color: #ffefb7;
    color: #e9af00;
  }
  &.big {
    background-color: #fed7d4;
    color: #da1e28;
  }
}

.value {
  position: absolute;
  font-size: 12px;
  top: -1px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  padding-right: 3px;
  padding-left: 3px;

  &.positive {
    left: 100%;
  }
  &.negative {
    right: 100%;
  }
}
