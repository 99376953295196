.wrapper {
  @apply relative;

  &.m {
    @apply h-9;
  }

  &.sm {
    @apply h-8;
  }

  &.xs {
    @apply h-6;
  }

  &.actions,
  &.templates {
    @apply h-9 shrink-0;

    .prefix {
      @apply left-2;
    }
  }
}

.root[data-invalid] {
  // FIXME: Is this used?
  @apply border-feedback-error border-solid border-2;
}

.root {
  @apply text-gray border-gray-border border-solid border p-3 h-11 rounded w-full ease-in-out duration-300;

  &.m {
    @apply p-2 h-9;
  }

  &.sm {
    @apply p-2 h-8;
  }

  &.xs {
    @apply p-2 h-6 text-sm leading-4;
  }

  &.hasError {
    @apply border-feedback-error;
  }

  &:focus {
    @apply border-primary-lighter border border-solid outline-none;
  }

  &::placeholder {
    @apply text-gray-lighter font-medium text-base leading-6 opacity-100;
  }

  &.templates {
    @apply bg-gray-lighter text-gray-light;
  }

  &.actions,
  &.templates {
    @apply font-bold border-0 pl-11 h-9;
    border-radius: 36px;

    &:focus,
    &:hover {
      @apply border-0;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    }

    /* do not group these rules */ //for some reason it makes it not work in chrome/firefox
    &::-webkit-input-placeholder {
      @apply font-bold;
    }
    &:-moz-placeholder {
      /* FF 4-18 */
      @apply font-bold;
    }
    &::-moz-placeholder {
      /* FF 19+ */
      @apply font-bold;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      @apply font-bold;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      @apply font-bold;
    }
    &::placeholder {
      /* modern browser */
      @apply font-bold;
    }
  }
}

.root[disabled] {
  @apply bg-disabled text-muted cursor-not-allowed opacity-100;
}

.hasPrefix {
  @apply pl-8;
}

.hasSuffix {
  @apply pr-8;
}

.spaced {
  &.hasPrefix {
    @apply pl-9;
  }

  &.hasSuffix {
    @apply pr-9;
  }
}

.prefix,
.suffix {
  @apply absolute top-1/2 flex;
  transform: translateY(-50%);
}

.prefix {
  @apply left-3;
}

.suffix {
  @apply right-3;
}
