.root {
  @apply h-full w-full;
}

.loading {
  @apply bg-transparent relative;
}

.backdrop {
  @apply top-0 left-0 w-full h-full absolute z-20;
  backdrop-filter: blur(2px) saturate(40%)
}