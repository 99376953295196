.headline {
  color: inherit;
  
  &.xs {
    @apply font-bold text-sm leading-4;
  }

  &.s {
    @apply font-bold text-base leading-5;
  }

  &.m {
    @apply font-bold text-lg leading-6;
  }

  &.l {
    @apply font-black text-xl leading-7;
  }

  &.xl {
    @apply font-black text-2xl leading-8;
  }

  &.xxl {
    @apply font-black text-3xl leading-9;
  }
}

.body {
  @apply text-gray;

  &.xs {
    @apply font-medium text-sm leading-4;
  }

  &.s {
    @apply font-medium text-base leading-6;
  }

  &.m {
    @apply font-medium text-lg leading-7;
  }
}

.link {
  @apply text-primary-lighter hover:text-primary-link;

  &.xs {
    @apply font-bold text-sm leading-4;
  }

  &.s {
    @apply font-bold text-base leading-6;
  }

  &.m {
    @apply font-bold text-lg leading-7;
  }
}


.buttonLabel {
  &.xs {
    @apply font-bold text-xs leading-3;
  }

  &.s {
    @apply font-bold text-sm leading-4;
  }

  &.m {
    @apply font-bold text-base leading-6;
  }

  &.l {
    @apply font-bold text-lg leading-7;
  }
}


.table {
  &.s {
    @apply font-medium text-gray-light text-base leading-4;
  }

  &.m {
    @apply font-bold text-primary-darker text-base leading-4;
  }
}


.caption {
  @apply text-gray;

  &.s {
    @apply font-normal text-xxs leading-3;
  }

  &.m {
    @apply font-normal text-sm leading-4;
  }
}


.overline {
  @apply text-gray uppercase tracking-wide;
  letter-spacing: 0.0625rem;

  &.xs {
    @apply font-bold text-xs leading-3;
  }

  &.s {
    @apply font-bold text-sm leading-4;
  }
}


.display {
  @apply text-gray;

  &.s {
    @apply font-black text-lg leading-6;
  }

  &.m {
    @apply font-black text-xl leading-7;
  }

  &.l {
    @apply font-black text-3xl leading-9;
  }

  &.xl {
    @apply font-black text-4xl leading-10;
  }

  &.xxl {
    @apply font-black text-5xl leading-11;
  }
}


.filter {
  @apply text-gray-light;

  &.m {
    @apply font-medium text-base leading-4;
  }
}