@import "../../styles/variables.css";

:global(#root) .picker,
:global(.modal-body) .picker {
  border-radius: 36px;
  height: 36px;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  }

  &.gray {
    @apply bg-gray-lighter;
  }

  svg {
    fill: #878d96;
    margin-right: 4px;
  }

  &.templates {
    @apply font-bold border-0 bg-gray-lighter text-gray-light h-7 transition-all duration-300;
    border-radius: 36px;

    &:hover {
      @apply text-gray-light;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    }
  }
}

.selection {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 24px;
  white-space: nowrap;
  line-height: 36px;
  color: #878d96;
  font-weight: 700;
  height: 100%;

  &.hasValue {
    color: var(--primary-6);

    svg {
      fill: var(--primary-6);
    }
  }
}

.popup {
  pointer-events: all;
  top: 120px !important;

  :global(.ant-calendar-range-middle),
  :global(.ant-calendar-input-wrap) {
    display: none;
  }
}

.mobile {
  left: 0 !important;
  width: 100%;

  :global(.ant-calendar-date-panel) {
    @apply flex flex-col items-center;

    :global(.ant-calendar-range-part) {
      @apply w-full;
    }
  }
  :global(.ant-calendar-range.ant-calendar-week-number) {
    @apply w-auto;
  }
}

.weekly {
  :global(.ant-calendar-week-number .ant-calendar-body tbody tr) {
    cursor: pointer;

    &:hover {
      background: #f3f5f7;
    }
  }
}

:global(.ant-calendar-week-number) :global(.ant-calendar-body) tr {
  cursor: default;

  &:hover {
    background: transparent;
  }
}

.mobileContainer {
  :global(.ant-calendar-picker-container) {
    @apply overflow-auto;
    height: calc(100% - 70px);
  }

  :global(.ant-calendar) {
    box-shadow: none;
  }

  :global(.ant-calendar-header) {
    margin-top: 0;
  }

  .popup {
    top: 70px !important;
  }
}
