.buttons {
    @apply flex justify-between;
}

.heading {
    @apply relative w-fit;
}

.copy {
    @apply relative mr-5 ml-0;
}

.content {
    @apply relative mt-0 -top-1;
}

.trigger {
    @apply relative mr-0 p-0 right-0 left-auto -top-4.5;
}
