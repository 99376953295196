@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.trigger {
  @apply flex justify-between cursor-pointer items-center mx-auto text-gray-light font-bold text-base;

  & > svg {
    @apply block mr-2 w-4 h-4 transition-all duration-300;
  }

  & > svg.rotated {
    rotate: 180deg;
  }

  &.right {
    @apply flex-row-reverse;
  }
}

.content {
  @apply overflow-hidden mt-4;
}

.content[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.content[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}
