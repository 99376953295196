.container {
  & > div:not(:global(.fresnel-container)), & > input, & > a, & > span {
    @apply mb-2 mr-2;
  }
}

.containerSmall {
  & > div:not(:global(.fresnel-container)), & > input, & > a, & > span {
    @apply mb-2 mr-2;
  }
}
