.select {
  @apply text-base;

  & > div:first-of-type {
    box-shadow: none !important;
  }

  & > div > div {
    @apply leading-none;
  }
}

.tagLine {
  @apply flex justify-start items-center;

  & > div {
    @apply flex space-x-1 flex-shrink max-w-full overflow-hidden;
  }
}

.editIcon {
  @apply flex invisible ml-2 cursor-pointer;

  & > img {
    @apply w-5 h-5;
  }
}

.wrapper {
  @apply z-100;
}
