.wrapper {
  @apply mb-4;
}

.label {
  @apply block;
}

.error {
  @apply mt-1 -mb-0.5;
}

span,
div,
a,
* {
  &.hasError {
    @apply border-feedback-error;
  }
}
