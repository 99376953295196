@import '../../styles/variables.css';

:global(#root) .picker {
  border-radius: 36px;
  height: 36px;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  }

  &.gray {
    @apply bg-gray-lighter;
  }

  svg {
    fill: #878d96;
    margin-right: 4px;
  }

  input {
    @apply border-none bg-white text-transparent cursor-pointer;
    border-radius: 36px;
  }

  i {
    @apply hidden;
  }
}

:global(.ant-calendar-picker-container) :global(.ant-calendar-time) :global(.ant-calendar-footer-btn) {
  @apply text-base w-full flex justify-between items-center;

  & :global(.ant-calendar-ok-btn) {
    @apply rounded-lg text-base bg-primary p-1 leading-none h-8 flex items-center my-1;
  }
}

.selection {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 24px;
  white-space: nowrap;
  line-height: 36px;
  color: #878d96;
  font-weight: 700;

  &.hasValue {
    color: var(--primary-6);

    svg {
      fill: var(--primary-6);
    }
  }
}

.popup {
  top: 120px !important;

  :global(.ant-calendar-range-middle),
  :global(.ant-calendar-input-wrap) {
    display: none;
  }

  :global(.ant-calendar-date-panel) {
    @apply flex flex-col;
  }

  :global(.ant-calendar-time-picker-combobox) {
    & > div:last-child {
      @apply hidden;
    }
  }

  :global(.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select) {
    @apply w-1/2 text-base;
    height: 274px; // dirty hack so people don't see and can click on the calendar behind.
  }
}

.mobile {
  left: 0 !important;
  width: 100%;

  :global(.ant-calendar-date-panel) {
    @apply flex flex-col items-center;
  }
  :global(.ant-calendar-range.ant-calendar-week-number) {
    @apply w-auto;
  }
}

.weekly {
  :global(.ant-calendar-week-number .ant-calendar-body tbody tr) {
    cursor: pointer;

    &:hover {
      background: #f3f5f7;
    }
  }
}

:global(.ant-calendar-week-number) :global(.ant-calendar-body) tr {
  cursor: default;

  &:hover {
    background: transparent;
  }
}

.mobileContainer {
  :global(.ant-calendar-picker-container) {
    @apply overflow-auto;
    height: calc(100% - 70px);
  }

  :global(.ant-calendar) {
    box-shadow: none;
  }

  :global(.ant-calendar-time) {
    @apply w-full;
  }

  :global(.ant-calendar-header) {
    @apply mt-0 w-full;
  }

  :global(.ant-calendar-footer) {
    @apply w-full;
  }
 
  .popup {
    top: 70px !important;
  }
}
