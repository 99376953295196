.wrapper {
  @apply text-gray-light flex items-center shrink-0 pl-3 pr-2 select-none bg-white transition-all duration-300 rounded border-solid border border-gray-border;
  &[disabled] {
    @apply bg-disabled cursor-not-allowed pr-3;
  }

  /* default size */
  min-height: 44px;
  line-height: 42px;

  &.hasError {
    @apply border-solid border border-feedback-error;
  }
}

.prefixWrapper {
  @apply flex mr-2 shrink-0 text-gray-light;
  &.hasValue {
    @apply text-gray;
  }
}
