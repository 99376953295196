.container {
  @apply flex flex-col items-center justify-center w-full h-full mx-auto;

  img {
    @apply h-80;
  }

  .description {
    @apply text-center text-primary;
  }
}

.message {
  @apply mt-8 font-bold text-primary z-10 text-center;
}

.small {
  .message {
    @apply mt-0 text-sm;
  }
}
