.card {
    @apply m-0 p-0 relative bg-white transition-all duration-300 border-none rounded-lg hover:z-10;

    &.chartCard {
      @apply overflow-hidden;
      .extra {
        min-width: 36px;
        @apply p-0;
      }
      .body {
        max-height: calc(100% - var(--table-header-height));
        @apply h-full p-3; // flex flex-col;
      }
      .headWrapper {
        height: var(--table-header-height);
      }
      .head {
        padding: 0 10px;
        min-height: var(--table-header-height);
      }
    }

    //Remove when replacing ant-form
    :global(.ant-form label) {
        @apply mb-2 text-base;
    }
}
.chartCard:not(:global(.type-text)) {
  @apply desktop:hover:shadow-grid 
}
:global(.type-text) {
  &.chartCard:hover .body {
    @apply desktop:bg-accent-neutral-5;
  }
}
.square {
    @apply rounded-none;
}
:global(.fresnel-lessThan-tablet), div[data-orientation] {
  .card {
    @apply mb-4 desktop:max-w-xl mobile:rounded-none mobile:mt-3 tablet:rounded-none tablet:mt-3;
  }
}

.head {
    min-height: 48px;
    margin-bottom: -1px;
    border-bottom-style: solid;
    @apply px-6 bg-transparent border-b border-b-disabled rounded-t-sm;
}

.headWrapper {
    @apply flex items-center;
}

.title {
    @apply text-lg font-bold leading-6 text-primary-darker inline-block flex-1 py-4 overflow-hidden whitespace-nowrap overflow-ellipsis;
}

.extra {
  @apply float-right ml-auto py-4;
}

.extraFloating {
  @apply absolute right-2 top-1 z-10;
}

.body {
    @apply text-base p-6;
}
