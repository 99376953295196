@import "../../styles/variables.css";

.sidebar {
  @apply fixed z-50 h-screen transition-all duration-200 bg-white;
  flex: 0 0 237px;
  max-width: 237px;
  min-width: 237px;
  width: 237px;
  &.collapsed {
    flex: 0 0 80px;
    max-width: 80px;
    min-width: 80px;
    width: 80px;

    .item,
    .collapsible {
      @apply px-3;
      span {
        @apply max-w-0;
      }
    }
    .icon{
      @apply m-0;
    }
  }
  span{
    @apply whitespace-nowrap overflow-hidden;
  }
}

.menu {
  @apply relative h-full;
}

.sub {
  &.bottom {
    @apply absolute bottom-24 left-0 right-0;
  }
}

.list {
  @apply flex flex-col h-full list-none p-0 m-0;
}

.logo {
  @apply flex;
  /* svg has wrong width */
  @apply mx-auto;
  @apply mt-3.5;
  /* random pixel value since it should be somehow aligned to the header */
  margin-bottom: 100px;
}

.item {
  @apply px-3 mb-1;

  a,
  button {
    @apply relative flex items-center w-full font-bold cursor-pointer h-10 text-gray-light rounded pl-3.5;
    &::before {
      @apply absolute left-0 top-0 bottom-0 right-0;
      content: "";
    }
    &:focus {
      //@apply outline outline-2 outline-primary-lighter;
    }
  }

  &:after {
    @apply hidden;
  }

  &:not(:last-of-type) {
    @apply mb-2;
  }

  &:hover {
    a,
    button {
      @apply bg-gray-lighter;
    }
  }

  &.active {
    a,
    button {
      @apply bg-primary-tint text-primary-lighter;
    }
    .icon {
      @apply text-primary-lighter;
    }
  }
}
.collapsible {
  @apply px-3 mb-1 bg-white;

  .collapsibleTrigger {
    @apply relative flex items-center w-full font-bold cursor-pointer h-10 text-gray-light rounded pl-3.5 my-1;
  }

  .collapsibleContent {
    @apply mt-2 -mx-3;
  }
}

.icon {
  @apply w-6 h-6 text-gray-light mr-2.5 duration-200;
}

.toggler {
  @apply rounded-full bg-primary-lighter text-white flex absolute p-1 cursor-pointer bottom-6 -right-3;
  &.rotated {
    transform: rotate(180deg);
  }
}

.badgeWrapper {
  @apply w-full flex items-center;

  .indicator {
    @apply flex items-center justify-end mr-2 grow;
  }

  svg {
    @apply flex-shrink-0;
  }
}

.collapsed {
  .badgeWrapper {
    .indicator {
      @apply -left-2 top-2 relative;
    }
  }
}
