.layout {
    @apply flex flex-auto bg-gray-lighter;
}

.container {
    @apply h-full flex-row;
}

.inner {
    @apply desktop:transition-all desktop:duration-300 overflow-x-hidden;
}

.content {
    @apply flex flex-auto flex-col w-full;
}
