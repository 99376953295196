.flexBetweenCell {
  @apply flex items-center justify-between h-full;
}

.flexRightCell {
  @apply flex items-center justify-end h-full;

  & > button {
    @apply flex items-center justify-end h-full;
  }
}

.flexCenterCell {
  @apply flex items-center justify-center h-full;
}

.monoCell {
  font-family: "JetBrains Mono", "Cascadia Code", ui-monospace, monospace !important;
}

.gramsPerNumSoldCell {
  @apply flex items-center h-full;
}

.badge {
  @apply w-3 h-3 mr-3;
  margin-right: 12px;

  &.green {
    background-color: #6df1a5;
  }

  &.yellow {
    background-color: #fde17d;
  }

  &.red {
    background-color: #ff3a45;
  }
}

.svgButton {
  @apply cursor-pointer p-0 m-0;

  svg {
    @apply w-4 h-4;
  }
}

.enterBtn {
  @apply w-full h-full cursor-pointer p-0 m-0 text-base flex items-center justify-between;

  & > span {
    @apply overflow-hidden text-ellipsis;
  }
}

.delayed {
  @apply text-feedback-error;
}

.alarmIcon {
  @apply w-5 h-5 flex-shrink-0 mr-1;
}

.hoverBtn {
  @apply cursor-pointer invisible p-0 m-0;

  svg {
    @apply w-4 h-4;
  }
}

:global(.ag-row-hover) {
  .hoverBtn {
    @apply visible;
  }
}

.tagLine {
  @apply flex space-x-1 flex-shrink max-w-full overflow-hidden items-center h-full;
}

.buttons {
  @apply h-full flex items-center justify-start space-x-2;
}

.address {
  @apply m-0 not-italic overflow-hidden flex-shrink;

  & > span {
    @apply block whitespace-nowrap overflow-hidden text-ellipsis;
  }
}

.address.oneline {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}