.root {
  @apply rounded-full cursor-pointer font-bold flex justify-start items-center whitespace-nowrap relative py-px px-1;

  &.reversed {
    @apply flex-row-reverse;
  }

  & > div.buttonLoader {
    @apply absolute right-4;
  }

  svg {
    @apply shrink-0 fill-current;
  }

  .text {
    @apply overflow-hidden text-ellipsis;
  }
}

.withText:not(.link) {
  &.xs {
    > .icon {
      @apply flex-shrink-0 ml-1.5 mt-0.5 mb-0.5 mr-0.5;
    }

    &.reversed > .icon {
      @apply mr-1.5 mt-0.5 mb-0.5 ml-0.5;
    }

    &.textOnly > .text {
      @apply mx-2 my-1;
    }

    &.withIcon > .text {
      @apply mr-2 my-1;
    }

    &.reversed.withIcon > .text {
      @apply ml-2 mr-0 my-1;
    }
  }

  &.s {
    > .icon {
      @apply flex-shrink-0 ml-2 mt-1.5 mb-1.5 mr-1;
    }

    &.reversed > .icon {
      @apply mr-2.5 mt-1.5 mb-1.5 ml-2;
    }

    &.textOnly > .text {
      @apply mx-3 my-1.5;
    }

    &.withIcon > .text {
      @apply mr-3 my-1.5;
    }

    &.reversed.withIcon > .text {
      @apply ml-3 mr-0 my-1.5;
    }
  }
  
  &.m {
    > .icon {
      @apply flex-shrink-0 ml-2 mt-1.5 mb-1.5 mr-1;
    }

    &.reversed > .icon {
      @apply mr-2 mt-1.5 mb-1.5 ml-1;
    }

    &.textOnly > .text {
      @apply mx-3 my-2;
    }

    &.withIcon > .text {
      @apply mr-3 my-2;
    }

    &.reversed.withIcon > .text {
      @apply ml-3 mr-0 my-2;
    }
  }
  
  &.l {
    > .icon {
      @apply flex-shrink-0 ml-2.5 mt-2.5 mb-2.5 mr-2;
    }

    &.reversed > .icon {
      @apply mr-2.5 mt-2.5 mb-2.5 ml-2;
    }

    &.textOnly > .text {
      @apply mx-4 my-3;
    }

    &.withIcon > .text {
      @apply mr-4 my-3;
    }

    &.reversed.withIcon > .text {
      @apply ml-4 mr-0 my-3;
    }
  }
  
  &.xl {
    > .icon {
      @apply flex-shrink-0 ml-3.5 mt-3.5 mb-3.5 mr-2;
    }

    &.reversed > .icon {
      @apply mr-3.5 mt-3.5 mb-3.5 ml-2;
    }

    &.textOnly > .text {
      @apply mx-6 my-3.5;
    }

    &.withIcon > .text {
      @apply mr-6 my-3.5;
    }

    &.reversed.withIcon > .text {
      @apply ml-6 mr-0 my-3.5;
    }
  }
}

.iconOnly {
  @apply flex-shrink-0 justify-center items-center;
  
  &.xs {
    @apply w-5 h-5;
  }

  &.s {
    @apply w-7 h-7;
  }
  
  &.m {
    @apply w-9 h-9;
  }
  
  &.l {
    @apply w-11 h-11;
  }
  
  &.xl {
    @apply w-13 h-13;
  }

  .text {
    @apply hidden;
  }
}

.tertiary {
  @apply text-primary-lighter bg-transparent hover:bg-white;

  &.s {
    > .icon {
      @apply flex-shrink-0 ml-1 mt-0.5 mb-0.5 mr-0.5;
    }

    &.reversed > .icon {
      @apply mr-1 mt-0.5 mb-0.5 ml-0.5;
    }

    &.textOnly > .text {
      @apply mx-1 my-px;
    }

    &.withIcon > .text {
      @apply mr-0.5 my-px;
    }

    &.reversed.withIcon > .text {
      @apply ml-0.5 mr-0 my-px;
    }
  }
  
  &.m {
    > .icon {
      @apply flex-shrink-0 ml-1.5 mt-0.5 mb-0.5 mr-2;
    }

    &.reversed > .icon {
      @apply mr-1.5 mt-0.5 mb-0.5 ml-2;
    }

    &.textOnly > .text {
      @apply mx-2 my-0.5;
    }

    &.withIcon > .text {
      @apply mr-2 my-0.5;
    }

    &.reversed.withIcon > .text {
      @apply ml-2 mr-0 my-0.5;
    }
  }
  
  &.l {
    > .icon {
      @apply flex-shrink-0 ml-2 mt-0.5 mb-0.5 mr-1;
    }

    &.reversed > .icon {
      @apply mr-2 mt-0.5 mb-0.5 ml-1;
    }

    &.textOnly > .text {
      @apply mx-2 my-0.5;
    }

    &.withIcon > .text {
      @apply mr-2 my-0.5;
    }

    &.reversed.withIcon > .text {
      @apply ml-2 mr-0 my-0.5;
    }
  }

  &.disabled {
    @apply text-muted cursor-not-allowed hover:text-muted;
  }
}

.link {
  @apply text-primary-lighter bg-transparent hover:bg-white;


  &.textOnly > .text {
    @apply m-0 p-0 font-normal;
  }

  &.disabled {
    @apply text-muted bg-disabled cursor-not-allowed hover:bg-disabled hover:text-muted;
  }
}

.outlined {
  &.primary {
    @apply text-white bg-primary hover:bg-primary-darker;
  }
  
  &.secondary {
    @apply text-primary-lighter bg-primary-tint hover:bg-primary-lightest hover:text-primary-link;
  }

  &.neutral {
    @apply text-gray-neutral bg-gray-lighter hover:bg-disabled;
  }
  
  &.danger {
    @apply text-white bg-feedback-error hover:bg-feedback-error-darker;
  }
  
  &.dangerSecondary {
    @apply text-feedback-error bg-feedback-error-lighter hover:bg-feedback-error hover:text-white;
  }
  
  &.dangerLink {
    @apply text-feedback-error hover:text-feedback-error-darker p-0;
  }

  &.disabled {
    @apply text-muted bg-disabled cursor-not-allowed hover:bg-disabled hover:text-muted;
  }
}

.loading {
  @apply cursor-wait;
}

.pure {
  @apply bg-transparent;

  &.primary {
    @apply text-primary hover:text-primary-darker hover:bg-gray-lighter;
  }
  
  &.secondary {
    @apply text-primary-lighter hover:text-primary-link hover:bg-gray-lighter;
  }

  &.whiteHoverBg {
    @apply hover:bg-white;
  }

  &.neutral {
    @apply text-gray-light  hover:text-gray-neutral hover:bg-gray-lighter;
  }

  &.disabled {
    @apply text-muted cursor-not-allowed hover:text-muted hover:bg-transparent;
  }
}
