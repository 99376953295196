@import '../../styles/variables.css';

.rule {
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: #878d96;

  & + & {
    margin-top: 0.25rem;
  }

  &.checked {
    color: #3aa266;
  }
}

.icon {
  .checked & {
    fill: #daf8d6;

    path {
      fill: #3aa266;
    }
  }
}
