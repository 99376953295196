.container {
  @apply flex flex-col h-10 overflow-hidden;
}

.labels {
  @apply flex items-center text-sm font-bold leading-4 text-primary-darker;
}

.title {
  @apply w-5/12 overflow-hidden whitespace-nowrap overflow-ellipsis;

  span {
    @apply cursor-pointer hover:underline;
  }
}

.total {
  @apply text-sm w-20 ml-2 text-primary-darker text-left;
}

.tooltip {
  @apply cursor-help;
}