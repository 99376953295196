@import '../../styles/variables.css';

.options {
  @apply flex flex-wrap pt-6 pr-1 pb-4 pl-3;
}

.option {
  @apply flex items-center font-bold mr-2 mb-2 cursor-pointer py-1 pr-3 pl-1; 
  background-color: var(--primary-10);
  color: var(--primary-6);
  border-radius: 48px;
  transition: all 0.3s;

  svg {
    @apply mr-2;
    fill: var(--primary-6);
  }

  &:hover {
    background-color: var(--primary-3);
    color: var(--link-hover);

    svg {
      fill: var(--link-hover);
    }
  }

  &.checked {
    background-color: var(--primary-6);
    color: var(--white);

    svg {
      fill: var(--white);
    }
  }
}

.reset {
  @apply pl-3 pr-3 bg-gray-border text-gray hover:text-white hover:bg-gray-light;
}
