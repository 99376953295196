.root {
  @apply flex justify-start items-center whitespace-nowrap;

  &.reversed {
    @apply flex-row-reverse;
  }

  > .text {
    @apply text-current block;
  }

  &.xs {
    @apply h-4;

    > .icon {
      @apply flex-shrink-0 mx-0.5;
    }

    &.textOnly > .text {
      @apply mx-0.5;
    }

    &.withIcon > .text {
      @apply mr-0.5;
    }

    &.reversed.withIcon > .text {
      @apply ml-0.5 mr-0;
    }
  }

  &.s {
    @apply h-5;

    > .icon {
      @apply flex-shrink-0 mx-1;
    }

    &.textOnly > .text {
      @apply mx-1;
    }

    &.withIcon > .text {
      @apply mr-1;
    }

    &.reversed.withIcon > .text {
      @apply ml-1 mr-0;
    }
  }

  &.m {
    @apply h-7;

    > .icon {
      @apply flex-shrink-0 mx-2;
    }

    &.textOnly > .text {
      @apply mx-2;
    }

    &.withIcon > .text {
      @apply mr-2;
    }

    &.reversed.withIcon > .text {
      @apply ml-2 mr-0;
    }
  }

  &.l {
    @apply h-8;

    > .icon {
      @apply flex-shrink-0 mx-2;
    }

    &.textOnly > .text {
      @apply mx-2;
    }

    &.withIcon > .text {
      @apply mr-2;
    }

    &.reversed.withIcon > .text {
      @apply ml-2 mr-0;
    }
  }

  &.fbSuccess {
    @apply bg-feedback-success-tint text-feedback-success;
  }

  &.fbWarning {
    @apply bg-feedback-warning-tint text-feedback-warning;
  }

  &.fbError {
    @apply bg-feedback-error-lighter text-feedback-error;
  }

  &.fbInfo {
    @apply bg-feedback-info-tint text-feedback-info;
  }

  &.accentNeutral {
    @apply bg-primary-tint2 text-primary-darker;
  }

  &.accentCornflower {
    @apply bg-accent-cornflower-10 text-accent-cornflower-75;
  }

  &.accentGerbera {
    @apply bg-accent-gerbera-10 text-accent-gerbera-75;
  }

  &.accentMarigold {
    @apply bg-accent-marigold-10 text-accent-marigold-75;
  }

  &.accentNarcissus {
    @apply bg-accent-narcissus-10 text-accent-narcissus-75;
  }

  &.accentGras {
    @apply bg-accent-gras-10 text-accent-gras-75;
  }
  
  &.accentSpringstar {
    @apply bg-accent-springstar-10 text-accent-springstar-75;
  }

  &.accentLilac {
    @apply bg-accent-lilac-10 text-accent-lilac-75;
  }

  &.accentLavender {
    @apply bg-accent-lavender-10 text-accent-lavender-75;
  }

  &.accentPeachrose {
    @apply bg-accent-peachrose-10 text-accent-peachrose-75;
  }

  &.accentHydrangea {
    @apply bg-accent-hydrangea-10 text-accent-hydrangea-75;
  }

  &.accentRose {
    @apply bg-accent-rose-10 text-accent-rose-75;
  }

  &.light {
    &.accentNeutral {
      @apply bg-accent-neutral-5 text-accent-neutral-100 bg-opacity-60;
    }
  
    &.accentCornflower {
      @apply bg-accent-cornflower-5 text-accent-cornflower-100 bg-opacity-60;
    }
  
    &.accentGerbera {
      @apply bg-accent-gerbera-5 text-accent-gerbera-100 bg-opacity-60;
    }
  
    &.accentMarigold {
      @apply bg-accent-marigold-5 text-accent-marigold-100 bg-opacity-60;
    }
  
    &.accentNarcissus {
      @apply bg-accent-narcissus-5 text-accent-narcissus-100 bg-opacity-60;
    }
  
    &.accentGras {
      @apply bg-accent-gras-5 text-accent-gras-100 bg-opacity-60;
    }
    
    &.accentSpringstar {
      @apply bg-accent-springstar-5 text-accent-springstar-100 bg-opacity-60;
    }
  
    &.accentLilac {
      @apply bg-accent-lilac-5 text-accent-lilac-100 bg-opacity-60;
    }
  
    &.accentLavender {
      @apply bg-accent-lavender-5 text-accent-lavender-100 bg-opacity-60;
    }
  
    &.accentPeachrose {
      @apply bg-accent-peachrose-5 text-accent-peachrose-100 bg-opacity-60;
    }
  
    &.accentHydrangea {
      @apply bg-accent-hydrangea-5 text-accent-hydrangea-100 bg-opacity-60;
    }
  
    &.accentRose {
      @apply bg-accent-rose-5 text-accent-rose-100 bg-opacity-60;
    }
  }

  &.outline {
    @apply bg-white border border-solid;
    &.fbSuccess {
      @apply border-feedback-success text-feedback-success;
    }
  
    &.fbWarning {
      @apply border-feedback-warning text-feedback-warning;
    }
  
    &.fbError {
      @apply border-feedback-error text-feedback-error;
    }
  
    &.fbInfo {
      @apply border-feedback-info text-feedback-info;
    }
  
    &.accentNeutral {
      @apply border-primary-darker text-primary-darker;
    }
  
    &.accentCornflower {
      @apply border-accent-cornflower-75 text-accent-cornflower-75;
    }
  
    &.accentGerbera {
      @apply border-accent-gerbera-75 text-accent-gerbera-75;
    }
  
    &.accentMarigold {
      @apply border-accent-marigold-75 text-accent-marigold-75;
    }
  
    &.accentNarcissus {
      @apply border-accent-narcissus-75 text-accent-narcissus-75;
    }
  
    &.accentGras {
      @apply border-accent-gras-75 text-accent-gras-75;
    }
    
    &.accentSpringstar {
      @apply border-accent-springstar-75 text-accent-springstar-75;
    }
  
    &.accentLilac {
      @apply border-accent-lilac-75 text-accent-lilac-75;
    }
  
    &.accentLavender {
      @apply border-accent-lavender-75 text-accent-lavender-75;
    }
  
    &.accentPeachrose {
      @apply border-accent-peachrose-75 text-accent-peachrose-75;
    }
  
    &.accentHydrangea {
      @apply border-accent-hydrangea-75 text-accent-hydrangea-75;
    }
  
    &.accentRose {
      @apply border-accent-rose-75 text-accent-rose-75;
    }
  }
}
