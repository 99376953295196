.areaContainer {
  @apply relative overflow-auto rounded-b-md bg-code;
}

.area {
  @apply w-full p-4 text-sm border-none outline-none relative h-full;
}

.validator {
  @apply h-6 px-2 py-1 z-100 absolute top-0 left-0 right-0 rounded-t-md overflow-hidden text-sm whitespace-nowrap text-ellipsis bg-code;
}

.pretty {
  @apply overflow-hidden;
}

.button {
  @apply z-150 absolute top-1 right-3;
  &.editMode {
    @apply top-6 right-3;
  }
}
