.row {
  @apply -mx-2 relative h-auto block;
  &:first-of-type {
    @apply mb-4;
  }
  &::before,
  &::after {
    @apply table;
    content: "";
  }
  &::after {
    clear: both;
  }
}

.col-12,
.col-10,
.col-4 {
  @apply relative float-left block flex-shrink-0 flex-grow-0 px-2;
}

.col-12 {
  @apply w-1/2;
}

.col-10{
  @apply w-5/12;
}

.col-4{
  @apply w-1/6 flex justify-center pt-1;
}