.ant-calendar-picker-container {
  .ant-calendar {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  }

  .ant-calendar-range.ant-calendar-week-number {
    @media (--tablet-up) {
      width: 612px;
    }

    @media (--mobile) {
      width: 340px;
    }
  }

  .ant-calendar-range-right {
    @media (--mobile) {
      display: none;
    }
  }

  .ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
    @apply mobile:w-full w-1/2;
  }

  .ant-calendar-tbody>tr>td:first-child {
    text-align: left;
    width: 40px;
  }

  .ant-calendar-active-week {
    background: none !important;
    font-weight: initial !important;
  }

  .ant-calendar-in-range-cell:before {
    background: var(--primary-10) !important;
  }

  .ant-calendar-selected-day {
    position: relative;
    @apply rounded-lg;

    &:before {
      @apply rounded-lg absolute top-1 right-0 bottom-1 left-0 bg-primary-lighter;
      content: '';
    }

    & .ant-calendar-date {
      position: relative;
      z-index: 1;
      color: var(--white) !important;
      background: transparent;
    }
  }

  .ant-calendar-range .ant-calendar-in-range-cell>div {
    color: var(--primary-6);
  }

  .ant-calendar-today .ant-calendar-date {
    border-color: transparent;
    color: var(--primary-6);
  }

  .ant-calendar-selected-day:hover .ant-calendar-date {
    color: var(--white) !important;
    background: transparent;
  }

  .ant-calendar-date {
    @apply font-bold text-base leading-5 text-gray-light w-8 h-8;
  }

  .ant-calendar-last-month-cell .ant-calendar-date,
  .ant-calendar-next-month-btn-day .ant-calendar-date,
  .ant-calendar-last-month-cell .ant-calendar-date:hover,
  .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
    @apply text-disabled;
  }

  .ant-calendar-date:hover {
    @apply bg-gray-lighter rounded-lg;
  }

  .ant-calendar-week-number .ant-calendar-body tr {
    cursor: default;

    &:hover {
      background: transparent;
    }
  }

  .ant-calendar-date {
    @apply flex items-center justify-center;
  }

  .ant-calendar-week-number-cell {
    @apply font-normal text-xxs leading-3 text-gray-light h-8;
    cursor: default;
    white-space: nowrap;
  }

  .ant-calendar-range-left .ant-calendar-body {
    padding-right: 8px;
    padding-left: 20px;
  }

  .ant-calendar-range-right .ant-calendar-body {
    padding-right: 20px;
    padding-left: 8px;
  }

  .ant-calendar-range .ant-calendar-header,
  .ant-calendar-range .ant-calendar-month-panel-header,
  .ant-calendar-range .ant-calendar-year-panel-header,
  .ant-calendar-range .ant-calendar-decade-panel-header {
    margin-top: 15px;
  }

  .ant-calendar-range .ant-calendar-year-panel,
  .ant-calendar-range .ant-calendar-month-panel,
  .ant-calendar-range .ant-calendar-decade-panel {
    top: 49px;
  }

  .ant-calendar-range-left .ant-calendar-header {
    padding-left: 64px;
    padding-right: 13px;
  }

  .ant-calendar-range-right .ant-calendar-header {
    padding-left: 53px;
    padding-right: 25px;
  }

  .ant-calendar-footer {
    display: flex;
    line-height: 1;
  }

  .ant-calendar-footer-btn,
  .ant-calendar-footer-extra {
    /* IE11 */
    display: flex;
  }

  .ant-calendar-column-header .ant-calendar-column-header-inner {
    @apply font-bold text-base leading-5 text-primary-darker;
  }

  .ant-calendar-header .ant-calendar-century-select,
  .ant-calendar-header .ant-calendar-decade-select,
  .ant-calendar-header .ant-calendar-year-select,
  .ant-calendar-header .ant-calendar-month-select {
    @apply font-bold text-base leading-5 text-gray-light;
  }

  .ant-calendar-header .ant-calendar-next-month-btn {
    /* fix arrows overlapping */
    z-index: 1;
  }

  .ant-calendar-header .ant-calendar-prev-century-btn::after,
  .ant-calendar-header .ant-calendar-prev-decade-btn::after,
  .ant-calendar-header .ant-calendar-prev-year-btn::after {
    left: -9px;
  }

  .ant-calendar-header .ant-calendar-next-century-btn::before,
  .ant-calendar-header .ant-calendar-next-decade-btn::before,
  .ant-calendar-header .ant-calendar-next-year-btn::before {
    left: 9px;
  }

  .ant-calendar-header .ant-calendar-prev-century-btn::before,
  .ant-calendar-header .ant-calendar-prev-decade-btn::before,
  .ant-calendar-header .ant-calendar-prev-year-btn::before,
  .ant-calendar-header .ant-calendar-prev-century-btn::after,
  .ant-calendar-header .ant-calendar-prev-decade-btn::after,
  .ant-calendar-header .ant-calendar-prev-year-btn::after,
  .ant-calendar-header .ant-calendar-next-century-btn::before,
  .ant-calendar-header .ant-calendar-next-decade-btn::before,
  .ant-calendar-header .ant-calendar-next-year-btn::before,
  .ant-calendar-header .ant-calendar-next-century-btn::after,
  .ant-calendar-header .ant-calendar-next-decade-btn::after,
  .ant-calendar-header .ant-calendar-next-year-btn::after,
  .ant-calendar-header .ant-calendar-prev-month-btn::before,
  .ant-calendar-header .ant-calendar-prev-month-btn::after,
  .ant-calendar-header .ant-calendar-next-month-btn::before,
  .ant-calendar-header .ant-calendar-next-month-btn::after {
    @apply border-gray-light w-3 h-3;
    border-width: 2px 0 0 2px;
  }

  .ant-calendar-range .ant-calendar-body,
  .ant-calendar-range .ant-calendar-month-panel-body,
  .ant-calendar-range .ant-calendar-year-panel-body,
  .ant-calendar-range .ant-calendar-decade-panel-body {
    border-color: transparent;
  }
}

.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before,
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  display: none;
}
