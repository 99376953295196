//@media (prefers-color-scheme: light) {  // For when we support (dark | light) mode
.w-tc-editor {
  --color-fg-default: #c9d1d9;
  --color-canvas-subtle: #272822;
  --color-prettylights-syntax-comment: #959da6;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #fff;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-markup-bold: #c9d1d9;
}