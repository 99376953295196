@import '../../styles/variables.css';

.progress {
  :global(.ant-progress-inner) {
    background: none;
  }
}

.bars {
  @apply mt-12;
  .card & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .modal & {
    overflow-y: auto;
    max-height: calc(100vh - 236px);
  }
}

.noData {
  @apply flex justify-center items-center h-full w-full
}

.bar {
  margin: 0.375rem;
  @apply flex flex-col
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.name {
  flex-basis: 40%;
}

.name,
.total {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--primary-9);
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.total {
  @apply w-20;
}

.primary {
  color: var(--primary-9) !important;
}

.delta {
  @apply relative pl-3 font-bold whitespace-nowrap;
  font-size: 12px;
  line-height: 16px;
  color: #878d96;
  letter-spacing: 0.1px;

  &.left:before,
  &.up:before,
  &.down:before,
  &.upRed:before,
  &.downGreen:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    width: 0;
    height: 0;
  }

  &.left:before {
    border-top: 5px solid transparent;
    border-right: 6px solid #4094da;
    border-bottom: 5px solid transparent;
  }
  &.up:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #3aa266;
  }
  &.down:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #da1e28;
  }
  &.upRed:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #da1e28;
  }
  &.downGreen:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #3aa266;
  }
}

.modalFooter {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  border-top: 1px solid #dde1e6;
  height: 48px;
}

.closeWindow {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--primary-6);
  line-height: 48px;
}

.modal {
  .bars {
    @apply mt-0;
  }
}

.wrapper {
  height: 100%;

  :global(.ant-spin-container) {
    height: 100%;
    overflow: hidden;
  }
}

.inModal {
  :global(.ant-spin-container) {
    overflow: auto;
  }

  .bars {
    @apply mt-0;
  }
}