@import '../../styles/variables.css';

.picker {
  border: 1px solid #c1c7cd;
  border-radius: 4px;
  background-color: #fff;
  color: #878d96;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  :global(.has-error) &,
  &:global(.has-error) {
    border-color: var(--error-color);
  }

  svg {
    fill: #878d96;
    margin-right: 12px;
  }
}

.selection {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  line-height: 42px;
  color: #878d96;
  height: 100%;

  &.hasValue {
    color: #343a3f;
  }
}

.popup {
  :global(.ant-calendar-input-wrap) {
    height: 44px;
  }
}
