.container {
  @apply flex flex-col items-center justify-center w-full h-full mx-auto;

  img {
    @apply h-80;
  }

  .description {
    @apply text-center;
  }
}

.error {
  @apply mt-8 font-bold text-feedback-error z-10;
}

.code {
  @apply relative flex items-center justify-start p-6 my-4 bg-white rounded-lg text-gray;

  svg {
    @apply absolute w-8 h-8 top-1 left-1 text-gray-lighter;
  }
}

.small {
  .error {
    @apply text-base text-center mt-0;
  }

  .description {
    @apply text-sm;
  }
}
