#root {
  .ant-select, .ant-select-dropdown-menu-item {
    @apply text-base;
  }

  .ant-select-dropdown {
    @apply overflow-hidden;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  }
  
  .ant-select-dropdown-menu {
    @apply p-0
  }
  
  .ant-select-dropdown-menu-item {
    @apply text-gray-light;
  }
  
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    @apply bg-gray-lighter;
  }
  
  .ant-select-dropdown-menu-item-selected {
    @apply bg-primary-tint text-primary-lighter;
  }
  
  .ant-select-selection__placeholder {
    @apply text-gray-light;
  }
}
