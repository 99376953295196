@import '../../styles/variables.css';


.item {
  @apply flex flex-col items-center justify-center flex-1;
}

.name {
  @apply whitespace-nowrap;
}

.icon {
  @apply flex-shrink-0 h-6 text-muted;

  .active & {
    @apply text-primary-lighter;
  }
}
