.dot {
  @apply relative flex h-6 w-6 flex-none items-center justify-center bg-white;

  & > div {
    @apply h-1.5 w-1.5 rounded-full bg-gray-border;
  }
}

.diff {
  @apply flex min-w-4.5;
}

.spacer {
  @apply absolute left-0 top-0 flex w-6 justify-center;

  & > div {
    @apply w-px bg-gray-border;
  }
}
