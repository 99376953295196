.container {
  @apply flex flex-col w-full h-screen px-0 pt-4 text-white bg-gray-lighter desktop:h-screen-60;
}

.output {
  @apply h-full w-full text-sm text-gray whitespace-pre border-none bg-gray-lighter ring-0 focus:ring-0;
  font-family: "JetBrains Mono", "Cascadia Code", ui-monospace, monospace !important;
  resize: none;
}

.footer {
  @apply grid grid-cols-3 p-2 mt-2 text-sm text-black border-t-0 border-b border-l-0 border-r-0 border-solid bg-disabled border-gray-border bg-opacity-80;
  & > div {
    @apply flex items-center;
  }
}

.connectionStatus {
  @apply flex items-center;
}

.connectionIcon {
  @apply w-4 h-4 mr-2 rounded-full;
}