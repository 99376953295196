.root {
  @apply flex flex-col flex-grow relative overflow-visible m-0 p-0;
}

.contents {
  @apply flex-grow;
}

.content {
  @apply w-full duration-500 ease-in-out;
  &[data-state="inactive"] {
    @apply h-0 opacity-0 overflow-hidden p-0 pointer-events-none;
  }
  &[data-state="active"] {
    @apply h-full;
  }
}

.list {
  @apply relative h-8;
  &::before {
    @apply absolute block h-0.5 bg-disabled w-full bottom-0 left-0 right-0;
    content: "";
  }
}

.next,
.prev {
  @apply absolute top-0 bottom-1 flex items-center justify-center w-7 rounded-full bg-white cursor-pointer;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  &::before {
    @apply absolute block top-1/2 w-3 h-3 border-0 border-t-2 border-solid border-primary-lighter;
    content: "";
  }
}
.prev {
  @apply left-2;
  &::before {
    @apply border-l-2 left-2.5;
    transform: translateY(-50%) rotate(-45deg);
  }
}
.next {
  @apply right-2;
  &::before {
    @apply border-r-2 right-2.5;
    transform: translateY(-50%) rotate(45deg);
  }
}

.outer {
  @apply relative overflow-hidden h-8 -mb-0.5 pb-0.5;
}

.inner {
  @apply relative flex flex-row items-center w-fit h-full transition-all duration-500 ease-in-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* Chrome */
    @apply hidden;
  }
}

.tab {
  @apply relative font-bold text-gray-light hover:text-primary-lighter leading-7 p-0 mr-8 h-full whitespace-nowrap;
  letter-spacing: 0.1px;
  padding-bottom: 5px;
  &:first-of-type {
    @apply ml-3;
  }
  &:last-of-type {
    @apply mr-3;
  }
  &[data-disabled] {
    @apply hover:text-gray-light cursor-not-allowed;
  }
  &::after {
    @apply absolute block h-0.5 -bottom-0.5;
    content: "";
  }
  &[aria-selected="true"] {
    @apply text-primary-lighter;
    &::after {
      @apply -left-3 -right-3 bg-primary-lighter z-10;
    }
  }
}

.spaced {
  @apply mb-6;
}
